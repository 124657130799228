import React from "react"
import loadable from "@loadable/component"
import qs from "qs"
import tw from "twin.macro"

import { Helmet } from "react-helmet"

import Layout from "../layouts/base"
import SEO from "../components/seo"

const CTA = loadable(() => import("../partials/cta"))

const ThankYouPage = ({ location }) => {
	const siteTitle = "Thank You"
	const searched = qs.parse(location.search.replace(/^\?/, ""))

	return (
		<Layout>
			<SEO title={siteTitle} />
			<Helmet>
				<script type="text/javascript">
					{`
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						'event' : 'purchase',
						'items' : '${searched?.state ?? ""}'
					});
					`}
				</script>
			</Helmet>

			<main>
				<div className="container narrow" tw="py-16 md:py-24 xl:py-32">
					<h1 tw="mb-8 text-4xl">
						Order Confirmation #: <em>{searched?.order}</em>
					</h1>

					<h2 tw="text-lg font-semibold">
						Thank you for payment, your order is confirmed and the report/s are on the way.
					</h2>

					<p>Shortly, you will receive two emails: one with your receipt, and one with your reports.</p>

					<p>
						If you have any questions or don't receive your report/s within 2 hours, please call{" "}
						<a href="tel:1300730000">1300 730 000</a> or email{" "}
						<a href="supportau@dyedurham.com">supportau@dyedurham.com</a>
					</p>

					<hr tw="mt-8 border-t-0 border-b-2 border-catskill" />
				</div>

				<CTA heading="Instantly search property ownership & plan information" button="Search again"></CTA>
			</main>
		</Layout>
	)
}

export default ThankYouPage
